import React, { Component } from "react";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import contentData from "../utils/contentData";

class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5 mx-8 max-w-sm">
        <h2 className="my-6 text-center">Testing the Veri-Fryer</h2>
        <Row className="d-flex  justify-content-between">
          {contentData.map((col, i) => (
            <div className="max-w-sm">
         
              <h6 className="mb-3 text-red-700">Clic in "Login" to begin... create an account and follow the instructions</h6>
              <h5 className="mb-3">
                  {col.title}
              </h5>
              <div className="mx-80">
              <p>Are you looking for a reliable and secure way to verify the identity of individuals in Auth0 and create verifiable credentials? Look no further than Veri-Fryer! You can easily read the chips that are embedded in passports and ID cards, providing you with accurate and reliable information about the cardholder.</p>
<p>Whether you need to verify the identity of individuals for access to services, events, or sensitive information, Veri-Fryer has got you covered. And the best part? You can request a copy of the mobile app in Slack to @amba or at #hackathon.</p>
<p>If you're an iOS user, we've got you covered with a proven app that has been <s>extensively</s> tested (tested as in... it sporadically worked on my machine!🙈🙈🙈). And for Android users, we have an app that has not been tested but is ready for use.</p>
<p>With Veri-Fryer, you can easily integrate the information stored on the chip with Auth0 powered by Okta. This allows you to customize the authentication process to meet the needs of your organization or service. And with the recent launch from the Auth0 Labs, you can even create verifiable credentials using the information stored on the chip.</p>
<p>So why wait? Request your copy of Veri-Fryer today and experience the benefits for yourself! (You guessed it right... this text was written by an AI!😂😂😂)</p>
              </div></div>
          ))}
        </Row>
      </div>
    );
  }
}

export default Content;
