import React from "react";
import { Container, Row, Col } from "reactstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

function capitalizeText(text) {
  return text.toLowerCase().replace(/(?:^|\s)\S/g, function(firstLetter) {
    return firstLetter.toUpperCase();
  });
}

export const ProfileComponent = () => {
  const { user } = useAuth0();

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h3>Veri-fryed data:</h3>
          <h2>{capitalizeText(user['veri-fryed-data'].firstName)} {capitalizeText(user['veri-fryed-data'].lastName)}</h2>
          <p className="lead text-muted">{getNationalityFromISOCode(user['veri-fryed-data'].nationality)}</p>
        </Col>
      </Row>
      <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});


function getNationalityFromISOCode(isoCode) {
  const nationalities = {
    "AFG": "Afghan",
    "ALB": "Albanian",
    "DZA": "Algerian",
    "AND": "Andorran",
    "AGO": "Angolan",
    "ATG": "Antiguan or Barbudan",
    "ARG": "Argentine",
    "ARM": "Armenian",
    "AUS": "Australian",
    "AUT": "Austrian",
    "AZE": "Azerbaijani",
    "BHS": "Bahamian",
    "BHR": "Bahraini",
    "BGD": "Bangladeshi",
    "BRB": "Barbadian",
    "BLR": "Belarusian",
    "BEL": "Belgian",
    "BLZ": "Belizean",
    "BEN": "Beninese",
    "BTN": "Bhutanese",
    "BOL": "Bolivian",
    "BIH": "Bosnian or Herzegovinian",
    "BWA": "Botswana",
    "BRA": "Brazilian",
    "BRN": "Bruneian",
    "BGR": "Bulgarian",
    "BFA": "Burkinabé",
    "BDI": "Burundian",
    "KHM": "Cambodian",
    "CMR": "Cameroonian",
    "CAN": "Canadian",
    "CPV": "Cape Verdean",
    "CAF": "Central African",
    "TCD": "Chadian",
    "CHL": "Chilean",
    "CHN": "Chinese",
    "COL": "Colombian",
    "COM": "Comorian",
    "CRI": "Costa Rican",
    "CIV": "Ivorian",
    "HRV": "Croatian",
    "CUB": "Cuban",
    "CYP": "Cypriot",
    "CZE": "Czech",
    "DNK": "Danish",
    "DJI": "Djiboutian",
    "DMA": "Dominican",
    "DOM": "Dominican",
    "ECU": "Ecuadorian",
    "EGY": "Egyptian",
    "SLV": "Salvadoran",
    "GNQ": "Equatorial Guinean",
    "ERI": "Eritrean",
    "EST": "Estonian",
    "ETH": "Ethiopian",
    "FJI": "Fijian",
    "FIN": "Finnish",
    "FRA": "French",
    "GAB": "Gabonese",
    "GMB": "Gambian",
    "GEO": "Georgian",
    "DEU": "German",
    "GHA": "Ghanaian",
    "GRC": "Greek",
    "GRD": "Grenadian",
    "GTM": "Guatemalan",
    "GIN": "Guinean",
    "GNB": "Bissau-Guinean",
    "GUY": "Guyanese",
    "HTI": "Haitian",
    "HND": "Honduranean",
    "HUN": "Hungarian",
    "ISL": "Icelandic",
    "IND": "Indian",
    "IDN": "Indonesian",
    "IRN": "Iranian",
    "IRQ": "Iraqi",
    "IRL": "Irish",
    "ISR": "Israeli",
    "ITA": "Italian",
    "JAM": "Jamaican",
    "JPN": "Japanese",
    "JOR": "Jordanian",
    "KAZ": "Kazakhstani",
    "KEN": "Kenyan",
    "KIR": "I-Kiribati",
    "PRK": "North Korean",
    "KOR": "South Korean",
    "KWT": "Kuwaiti",
    "KGZ": "Kyrgyzstani",
    "LAO": "Laotian",
    "LVA": "Latvian",
    "LBN": "Lebanese",
    "LSO": "Basotho",
    "LBR": "Liberian",
    "LBY": "Libyan",
    "LIE": "Liechtenstein",
    "LTU": "Lithuanian",
    "LUX": "Luxembourgish",
    "MKD": "Macedonian",
    "MDG": "Malagasy",
    "MWI": "Malawian",
    "MYS": "Malaysian",
    "MDV": "Maldivian",
    "MLI": "Malian",
    "MLT": "Maltese",
    "MHL": "Marshallese",
    "MRT": "Mauritanian",
    "MUS": "Mauritian",
    "MEX": "Mexican",
    "FSM": "Micronesian",
    "MDA": "Moldovan",
    "MCO": "Monacan",
    "MNG": "Mongolian",
    "MNE": "Montenegrin",
    "MAR": "Moroccan",
    "MOZ": "Mozambican",
    "MMR": "Burmese",
    "NAM": "Namibian",
    "NRU": "Nauruan",
    "NPL": "Nepalese",
    "NLD": "Dutch",
    "NZL": "New Zealand",
    "NIC": "Nicaraguan",
    "NER": "Nigerien",
    "NGA": "Nigerian",
    "NIU": "Niuean",
    "NOR": "Norwegian",
    "OMN": "Omani",
    "PAK": "Pakistani",
    "PLW": "Palauan",
    "PAN": "Panamanian",
    "PNG": "Papua New Guinean",
    "PRY": "Paraguayan",
    "PER": "Peruvian",
    "PHL": "Filipino",
    "POL": "Polish",
    "PRT": "Portuguese",
    "QAT": "Qatari",
    "ROU": "Romanian",
    "RUS": "Russian",
    "RWA": "Rwandan",
    "KNA": "Kittitian or Nevisian",
    "LCA": "Saint Lucian",
    "VCT": "Saint Vincent and the Grenadines",
    "WSM": "Samoan",
    "SMR": "Sammarinese",
    "STP": "São Toméan",
    "SAU": "Saudi Arabian",
    "SEN": "Senegalese",
    "SRB": "Serbian",
    "SYC": "Seychellois",
    "SLE": "Sierra Leonean",
    "SGP": "Singaporean",
    "SVK": "Slovak",
    "SVN": "Slovene",
    "SLB": "Solomon Islander",
    "SOM": "Somali",
    "ZAF": "South African",
    "SSD": "South Sudanese",
    "ESP": "Spanish",
    "LKA": "Sri Lankan",
    "SDN": "Sudanese",
    "SUR": "Surinamese",
    "SWZ": "Swazi",
    "SWE": "Swedish",
    "CHE": "Swiss",
    "SYR": "Syrian",
    "TWN": "Taiwanese",
    "TJK": "Tajikistani",
    "TZA": "Tanzanian",
    "THA": "Thai",
    "TLS": "Timorese",
    "TGO": "Togolese",
    "TON": "Tongan",
    "TTO": "Trinidadian or Tobagonian",
    "TUN": "Tunisian",
    "TUR": "Turkish",
    "TKM": "Turkmen",
    "TUV": "Tuvaluan",
    "UGA": "Ugandan",
    "UKR": "Ukrainian",
    "ARE": "Emirati",
    "GBR": "British",
    "USA": "American",
    "URY": "Uruguayan",
    "UZB": "Uzbekistani",
    "VUT": "Vanuatuan",
    "VEN": "Venezuelan",
    "VNM": "Vietnamese",
    "YEM": "Yemeni",
    "ZMB": "Zambian",
    "ZWE": "Zimbabwean"
  };

  return nationalities[isoCode] || isoCode;
  }