const contentData = [
  {
    title: "Introducing tthe Veri-Fryer: A Secure Way to Read Passport Chips and ID Cards for Auth0 and Verifiable Credentials",
    description:
    `Are you looking for a reliable and secure way to verify the identity of individuals in Auth0 and create verifiable credentials? Look no further than Veri-Fryer! You can easily read the chips that are embedded in passports and ID cards, providing you with accurate and reliable information about the cardholder.

      Whether you need to verify the identity of individuals for access to services, events, or sensitive information, Veri-Fryer has got you covered. And the best part? You can request a copy of the mobile app in Slack to @amba or at #hackathon.
      
      If you're an iOS user, we've got you covered with a proven app that has been extensively tested (tested as in... it sporadically worked on my machine!🙈🙈🙈). And for Android users, we have an app that has not been tested but is ready for use.
      
      With Veri-Fryer, you can easily integrate the information stored on the chip with Auth0 powered by Okta. This allows you to customize the authentication process to meet the needs of your organization or service. And with the recent launch from the Auth0 Labs, you can even create verifiable credentials using the information stored on the chip.
      
      So why wait? Request your copy of Veri-Fryer today and experience the benefits for yourself! (You guessed it right... this text was written by an AI!😂😂😂)
    `
  }
];

export default contentData;
